<template>
  <div class="bg-white h-screen">
      <div v-if="this.$store.state.isLoading" class="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
          <span class="text-green-500 opacity-100 top-1/2 my-0 mx-auto block relative w-0 h-0" >
              <em class="fas fa-circle-notch fa-spin fa-5x"></em>
          </span>
      </div>
    <router-view />
  </div>
</template>

<script>

export default {
  name: "Main",
};
</script>

<style></style>

<template>
    <div class="flex items-center min-h-screen p-6 pt-0 bg-gray-100  dark:bg-gray-900">
        <div v-if="loading" class="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
            <span class="text-blue-500 opacity-100 top-1/2 my-0 mx-auto block relative w-0 h-0" >
                <i class="fas fa-circle-notch fa-spin fa-5x"></i>
            </span>
        </div>
        <div class="flex-1 h-full max-w-4xl mx-auto bg-white rounded-lg shadow-xl dark:bg-gray-800">
            <div class="flex flex-col md:flex-row">
                <div class="h-32 md:h-auto md:w-1/2">
                    <img
                    aria-hidden="true"
                    class="object-cover w-full h-full dark:hidden"
                    src="https://images.unsplash.com/photo-1446694292248-2c2a7e575b1b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                    alt="Office"/>
                </div>

                <div class="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
                    <div class="w-full">

                        <h1 class="mb-4 text-xl font-bold text-gray-700 dark:text-gray-200">Sign in to <span class="text-red-600">The Messenger</span></h1>

                        <form @submit.prevent="onSubmit">
                            
                            <label class="block mt-3 mb-2 font-bold text-gray-700" for="username">Username</label>
                            <input v-model="form.username" class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none" type="text" required>
                    

                            <label class="block mt-3 mb-2 font-bold text-gray-700" for="password">Password</label>
                            <input v-model="form.password" class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none" type="password" required>

                            <button type="submit" class="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-blue-500 border border-transparent rounded-lg cursor-pointer active:bg-green-600 hover:bg-blue-700">Login</button>
                        </form>

                        <div class="text-sm text-right">
                            Backend {{ backend_url }}<br>
                            Version {{ version }}
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import login from '@/services/login'
import { useSweetalertTopEnd } from '@/js/sweetalert'
import { version } from "../js/version";

export default {
    name:"Login",
    data() {
        const form = {
            username: '',
            password: '',
        }
        const backend_url = process.env.VUE_APP_MIS_URL;    
        let loading = false;
        return {
            form,
            backend_url,
            version,
            loading
        }
    },
    methods: {
        onSubmit(){
            this.loading = true;
            login.post(`/api/messenger_login`, this.form).then( response => {
                const success_response = response.data;
                localStorage.setItem('user', JSON.stringify(success_response))

                useSweetalertTopEnd(1500, 'success', 'Login success')
                this.$router.push("/");
            }).catch( error => {
                let error_response = error.response.data;
                this.loading = false;
                useSweetalertTopEnd(3000, 'error', error_response.message)
            });
        }
    },
}
</script>

<style scoped>

</style>
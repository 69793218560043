<template>
    <div class="relative" v-click-outside="clickedOutside">
        <input
            :value="value"
            @input="handleInput"
            :placeholder="placeholder"
            @keypress.enter="handleEnter"
            ref="input"
            tabindex="0"
            :class="inputClass"
        />
        <span class="absolute inset-y-0 right-0 flex items-center">
            <button @click="open_scanner" class="px-2 py-1 mr-2 focus:outline-none focus:shadow-outline border border-gray-200 hover:bg-gray-200 rounded-2xl">
                <em class="fas fa-barcode"></em>
            </button>
        </span>
        <div
            v-show="value && showOptions"
            @click.self="handleSelf()"
            @focusout="showOptions = false"
            tabindex="0"
            :class="dropdownClass"
        >
            <ul class="py-1">
                <li
                    v-for="(item, index) in list"
                    :key="index"
                    @click="handleClick(item)"
                    class="px-3 py-2 cursor-pointer hover:bg-gray-200"
                >
                    {{ item.model_name }}
                </li>
                <li v-if="loading" class="px-3 py-2 text-center text-gray-400">
                    Loading...
                </li>
                <li v-if="list.length==0 && value.length>=3 && loading==false" class="px-3 py-2 text-center text-gray-600">
                    No Matching Results / ไม่พบข้อมูล
                </li>
                <li v-if="value.length < 3" class="px-3 py-2 text-center text-gray-600">
                    Please enter at least 3 characters to search / กรุณาพิมพ์อย่างน้อย 3 ตัวอักษรเพื่อที่จะค้นหาสินค้า
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            required: false,
        },
        placeholder: {
            type: String,
            required: false,
            default: "Enter text here.",
        },
        list: {
            type: Array,
            required: true,
        },
        inputClass: {
            type: String,
            required: false,
            default:"border border-gray-300 py-2 px-3 rounded-md focus:outline-none focus:shadow-outline",
        },
        dropdownClass: {
            type: String,
            required: false,
            default:"absolute w-full z-50 bg-white border border-gray-300 mt-1 mh-48 overflow-hidden overflow-y-scroll rounded-md shadow-md",
        },
        loading: {
            type: Boolean,
            required: false,
        },
        open_scanner: {
            required: false,
        }
    },

    data() {
        return {
            showOptions: false,
            chosenOption: "",
            searchTerm: "",
        };
    },

    methods: {
        reset() {
            this.$emit("input", "");
            this.chosenOption = "";
        },

        handleInput(evt) {
            this.$emit("input:field", evt.target.value);
            this.searchTerm = evt.target.value;
            this.showOptions = true;
        },

        handleClick(item) {
            this.$emit("input", item.model_name);
            this.$emit("chosen", item);
            this.chosenOption = item.model_name;
            this.showOptions = false;
            this.$refs.input.focus();
        },

        handleEnter() {
            this.$emit("enter", this.value);
            this.showOptions = false;
        },

        clickedOutside() {
            this.showOptions = false;

            if (!this.chosenOption) {
                this.$emit("input", "");
            }
        },
    },
};
</script>

<style scoped>
.mh-48 {
    max-height: 10rem;
}
</style>

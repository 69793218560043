<template>
    <div v-if="open" class="modal-example">
        <div class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center overflow-hidden">
            <div @click="on_close" class="modal-overlay absolute w-full h-full backdrop-filter backdrop-blur-sm backdrop-brightness-50"></div>

            <div class="modal-container bg-white lg:w-1/2 sm:w-9/12  mx-auto rounded shadow-lg z-50 text-black">
                <!-- Add margin if you want to see some of the overlay behind the modal-->
                <div class="modal-content py-4 text-left px-6">
                    <!--Title-->
                    <div class="flex justify-between items-center pb-3">
                        <p class="font-bold">กรุณาติดต่อ Admin</p>
                        <div @click="on_close" class="modal-close cursor-pointer z-50">
                            <em class="fas fa-times"></em>
                        </div>
                    </div>
                    <hr class="mb-2" />
                    <!--Body-->
                    <div class="flex flex-wrap mb-3">
                        <div class="w-full lg:px-3 sm:px-1">
                            ในกรณีไม่มี Barcode, Serial Number และ Product Name  กรุณาติดต่อ admin
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ModalExample",
    props: ["open", "on_close"],
};
</script>

<style scope>
</style>

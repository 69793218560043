import { createApp } from "vue";
import App from "./App.vue";
import './styles/app.css'; // Here
import store from './store'
import router from './router'
import vClickOutside from "click-outside-vue3"

const app = createApp(App);
app.use(store)
app.use(router)
app.use(vClickOutside)
app.mount('#app')
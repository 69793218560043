import {
    createRouter,
    createWebHistory
} from 'vue-router'
import Main from '@/layouts/Main.vue'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'



function authGuard(to, from, next) {
    let isAuthenticated = false

    if (localStorage.getItem('user')) {

        var time_current = new Date();
        var userStorage = localStorage.getItem('user')
        let userStorageJSON = JSON.parse(userStorage)
        let time_expires_in = new Date(userStorageJSON['expires_in'])
        if (time_current >= time_expires_in) {
            isAuthenticated = false
        } else {
            isAuthenticated = true
        }
    } else {
        isAuthenticated = false
    }

    if (isAuthenticated) {
        next()
    } else {
        next({name: 'Login'})
    }
}

const routes = [{
        path: '/',
        component: Main,
        children: [{
            path: '',
            name: 'Home',
            component: Home,
            beforeEnter: authGuard
        }],
        meta: {
            title: 'Home'
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: 'Login'
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
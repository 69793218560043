<template>
    <div class="footer">
        <div class="max-w-3xl mx-auto px-4">
            <div class="flex justify-between">
                <div class="w-1/2">
                    <button
                        v-if="step > 1"
                        @click="previous_step"
                        class="w-32 focus:outline-none py-2 px-5 rounded-lg shadow-sm text-center text-yellow-600 bg-yellow-300 hover:bg-yellow-400 font-medium border"
                    >
                        ย้อนกลับ
                    </button>
                    <button
                        v-if="step < 2"
                        @click="reset_data"
                        class="w-32 focus:outline-none border border-transparent py-2 px-5 rounded-lg shadow-sm text-center text-white bg-red-500 hover:bg-red-600 font-medium"
                    >
                        ยกเลิก
                    </button>
                </div>

                <div class="w-1/2 text-right">
                    <button
                        v-if="step < 2"
                        @click="next_step"
                        class="w-32 focus:outline-none border border-transparent py-2 px-5 rounded-lg shadow-sm text-center text-white bg-blue-500 hover:bg-blue-600 font-medium"
                    >
                        ถัดไป
                    </button>

                    <button
                        @click="complete_step"
                        v-if="step === 2"
                        class="w-32 focus:outline-none border border-transparent py-2 px-5 rounded-lg shadow-sm text-center text-white bg-green-500 hover:bg-green-600 font-medium"
                    >
                        บันทึกข้อมูล
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "Footer",
        props: ['step','reset_data', 'next_step', 'previous_step', 'complete_step'],
    };
</script>

<style>
</style>
<template>
    <div className="App">
        <router-view />
    </div>
</template>

<script>
export default {
    name: "App",
    watch: {
        $route(to) {
            document.title = to.meta.title;
        },
    },
};
</script>

<template>
    <div class="step-validation">
        <div class="text-black block mb-2 text-center">
            <span class="text-xs text-gray-500">เวลา </span> <br>
            <span class="font-bold text-lg">{{ date_time_now }}</span>
        </div>
        <div class="text-black block my-2 text-center">
            <span class="text-xs text-gray-500">Dealer / ตัวแทนจำหน่าย</span> <br>
            <span class="font-bold text-lg">{{ dealer_options.find(item=>item.value==form.dealer_id).label }}</span>
        </div>
        <div class="text-black block my-2 text-center">
            <span class="text-xs text-gray-500">Barcode</span> <br>
            <span class="font-bold text-lg">{{ form.barcode }}</span>
        </div>
        <div class="text-black block my-2 text-center">
            <span class="text-xs text-gray-500">Model Name</span> <br>
            <span class="font-bold text-lg">{{ form.model_name }}</span>
        </div>
        <div class="text-black block my-2 text-center">
            <span class="text-xs text-gray-500">Serial Number</span> <br>
            <span class="font-bold text-lg">{{ form.serial ? form.serial : '-' }}</span>
        </div>
        <div class="text-black block my-2 text-center">
            <span class="text-xs text-gray-500">ถุง</span> <br>
            <span class="font-bold text-lg">{{ form.service_code }}</span>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    export default {
        name: "StepValidation",
        props: ['form', 'dealer_options'],
        data() {
            let date_time_now = moment().format("DD/MM/YYYY HH:mm");
            return {
                date_time_now
            }
        },
    };
</script>

<style>
    
</style>

